import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";

type TStore = {
    is_desktop: boolean;
    set_is_desktop: (v: boolean) => void;
    window_weight: number;
    set_window_weight: (v: number) => void;
};

const useGlob = create<TStore>()(
    immer(
        devtools(set => ({
            is_desktop: false,
            set_is_desktop: v => {
                set(s => {
                    s.is_desktop = v;
                });
            },
            window_weight: window.innerWidth,
            set_window_weight: v => {
                set(s => {
                    s.window_weight = v;
                });
            },
        })),
    ),
);

export default useGlob;
