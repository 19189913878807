import type { TRoute } from "@/helpers/types";
import { lazy } from "react";

const routes: TRoute[] = [
    {
        path: "create-paystub",
        page: lazy(() => import("@/pages/create-paystub")),
    },
    {
        path: "w2-form",
        page: lazy(() => import("@/pages/w2-form")),
    },
    {
        path: "1099-misc-form",
        page: lazy(() => import("@/pages/1099-misc-form")),
    },

    {
        path: "thank-you",
        page: lazy(() => import("@/pages/thank-you")),
    },
    {
        path: "profile",
        page: lazy(() => import("@/pages/profile")),
    },
    {
        path: "completion",
        page: lazy(() => import("@/pages/completion")),
    },
];

export default routes;
