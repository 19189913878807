import { useEffect } from "react";

import useGlob from "@/states/useGlob";

const useResizeEffect = () => {
    const { set_is_desktop, set_window_weight } = useGlob();

    useEffect(() => {
        const handle_resize = () => {
            set_is_desktop(window.innerWidth >= 1024);
            set_window_weight(document.documentElement.clientWidth);
        };
        handle_resize();
        window.addEventListener("resize", handle_resize);
        return () => window.removeEventListener("resize", handle_resize);
    }, [set_is_desktop, set_window_weight]);
};

export default useResizeEffect;
