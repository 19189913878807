import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import routes from "@/routes";
import useResizeEffect from "@/hooks/useResizeEffect";

const App = () => {
    useResizeEffect();

    return (
        <Suspense fallback={"Loading ... page"}>
            <BrowserRouter>
                <Routes>
                    {routes.map((route, i) => (
                        <Route
                            key={i}
                            path={route.path}
                            element={<route.page />}
                        />
                    ))}
                </Routes>
            </BrowserRouter>
            <Toaster
                toastOptions={{
                    className: "mt-5",
                }}
            />
        </Suspense>
    );
};

export default App;
