import ReactDOM from "react-dom/client";

import "./index.css";
import "react-advanced-cropper/dist/style.css";

import App from "./App";

const root_element = document.getElementById(
    "paystubscity-plugin-shortcode-root",
)!;

if (root_element) {
    ReactDOM.createRoot(root_element).render(<App />);
}
